<template>
	<v-card>
		<v-card-title class="grid-close">
			<span class="headline">
				Agregar productos en descuento
			</span>
			<v-btn
				style="right:10px; top:10px;"
				icon
				color="primary"
				fab
				absolute
				@click="$emit('cancel')">
				<v-icon dark>fa-times</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-container fluid>
				<v-row>
					<v-col v-if="loading" cols="12" sm="6" md="6">
						<v-skeleton-loader
							ref="skeleton"
							type="image" 
							class="mx-auto d-none d-md-block mt-5">
						</v-skeleton-loader>
					</v-col>
					<v-col v-if="loading" cols="12" sm="6" md="6">
						<v-skeleton-loader
							ref="skeleton"
							type="image" 
							class="mx-auto d-none d-md-block mt-5">
						</v-skeleton-loader>
					</v-col>
					<v-col cols="12" sm="12" md="12">
						<a-transfer
							v-if="!loading"
							:listStyle="{
								'height': '400px!important',
								 'width': '45%',
								'background' : 'white'
							}"
							:data-source="products"
							:titles="['Todos los productos', 'Productos vinculados']"
							:target-keys="selectedOptions"
							:selected-keys="selectedKeys"
							:show-search="true"
							@search="handleSearch"
							:render="item => item.title"
							:filter-option="filterOption"
							@change="handleChange"
							@selectChange="handleSelectChange"
							:locale= "{ itemUnit: '', itemsUnit: '', notFoundContent: 'Lista vacía', searchPlaceholder: 'Buscar sección' }"/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-divider class="mt-3 mr-2"></v-divider>
						<v-row justify="end">
							<v-btn v-if="!loading" @click="updateOptions" color="primary" class="mt-3 save-btn mr-4" dark>Aceptar</v-btn>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>    
</template>

<script>
import transfer from 'ant-design-vue/lib/transfer';
import { db, fb } from "@/firebase";
import { mapActions, mapState } from 'vuex';

export default {
	name: "add-remove-products",
	props: ['products'],
	components: {
		'a-transfer' : transfer
	},
	data() {
		return {      
			allProducts : [],
			selectedKeys: [],
			selectedOptions : [],
			loading : true
		}
	},
	computed: {
		...mapState(['selectedBusiness']),
	},
	methods: {
		handleChange(nextTargetKeys, direction, moveKeys) {        
			this.selectedOptions = nextTargetKeys;
		},
		handleSearch(dir, value) {
			return value.toUpperCase();
		},
		handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {        
			this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
		},
		filterOption(inputValue, option) {
			return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
		},
		updateOptions() {
			this.$emit('success',  this.selectedOptions.reverse())
		}
	},
	async mounted() {
		this.allProducts = this.products.map(item => {
			let product = item
			product.title = item.name ? item.name : '',
			product.key = item['.key']
			return product  
		})

		this.selectedOptions = this.products.filter(item => item.hasOwnProperty('discountPrice'))
		this.selectedOptions = this.selectedOptions.map(item => {
			return item['.key']
		})
		this.loading = false
	}
}
</script>
